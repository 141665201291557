/* You can add global styles to this file, and also import other style files */

@import "../../../src/styles.css";
@import "../../../style/sidenav.css";
@import "../../../style/flex.css";
body{
    overflow: hidden;
}

rect.selection {
    stroke          : gray;
    stroke-dasharray: 4px;
    stroke-opacity  : 0.5;
    fill            : transparent;
}
.adm-table {
    width:100%;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    min-height: 150px;
}
.adm-table th{
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: rgba(0, 0, 0, 0.06);
    color: black;


    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

}
.adm-table  td, th {


    border-right: 1px rgba(0, 0, 0, 0.02) solid;
    border-left: 1px rgba(0, 0, 0, 0.02) solid;
    text-align: center !important;
}
.adm-table  tr:nth-child(even){background-color: #f2f2f2;}

.adm-table  tr:hover {background-color: rgba(221, 221, 221, 0.12);}
.adm-table  .active{
    background: #00800033;
    color: white;
}
.adm-table  .elementChange{
    color: red;
}
.adm-table  .detail-row{
    height: 0;
}

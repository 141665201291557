@use '@angular/material' as mat;
//@use '~@angular/material' as mat;
@import url('https://fonts.googleapis.com/css?family=Poppins:400,400i,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,500,600,600i');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap');

@import 'colorTheming';

@include mat.core();
//@include mat.all-component-themes($dynamic-app-dark-theme)
@include mat.all-component-themes($dynamic-app-light-theme);
// Typography
$feq-typography: mat.define-typography-config(
    $font-family: 'Open sans',
    $display-4: mat.define-typography-level(112px, 112px, 300),
    $display-3: mat.define-typography-level(66px, 56px, 400),
    $display-2: mat.define-typography-level(55px, 48px, 400),
    $display-1: mat.define-typography-level(44px, 40px, 400),
    $headline: mat.define-typography-level(34px, 32px, 400),
    $title: mat.define-typography-level(18px, 32px, 500),
    $subheading-2: mat.define-typography-level(16px, 22px, 500),
    $subheading-1: mat.define-typography-level(15px, 24px, 400),
    $body-2: mat.define-typography-level(14px, 24px, 500),
    $body-1: mat.define-typography-level(14px, 30px, 400),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $button: mat.define-typography-level(14px, 14px, 500) // Line-height must be unit-less fraction of the font-size.
    // $input:         mat-typography-level(inherit, 1.125, 400)
);
$mta-typography: mat.define-typography-config(
    $font-family: 'Roboto, Arial, Helvetica, sans-serif',
    $display-4: mat.define-typography-level(112px, 112px, 300),
    $display-3: mat.define-typography-level(66px, 56px, 400),
    $display-2: mat.define-typography-level(55px, 48px, 400),
    $display-1: mat.define-typography-level(44px, 40px, 400),
    $headline: mat.define-typography-level(45px, 49px, 600),
    $title: mat.define-typography-level(18px, 32px, 500),
    $subheading-2: mat.define-typography-level(16px, 22px, 500),
    $subheading-1: mat.define-typography-level(15px, 24px, 400),
    $body-2: mat.define-typography-level(14px, 24px, 500),
    $body-1: mat.define-typography-level(14px, 30px, 400),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $button: mat.define-typography-level(16px, 16px, 400) // Line-height must be unit-less fraction of the font-size.
    // $input:         mat-typography-level(inherit, 1.125, 400)
);
$tkp-typography: mat.define-typography-config(
    $font-family: 'Poppins',
    $display-4: mat.define-typography-level(112px, 112px, 300),
    $display-3: mat.define-typography-level(66px, 56px, 400),
    $display-2: mat.define-typography-level(55px, 48px, 400),
    $display-1: mat.define-typography-level(44px, 40px, 400),
    $headline: mat.define-typography-level(34px, 32px, 400),
    $title: mat.define-typography-level(10px, 32px, 500),
    $subheading-2: mat.define-typography-level(16px, 22px, 500),
    $subheading-1: mat.define-typography-level(15px, 24px, 400),
    $body-2: mat.define-typography-level(14px, 24px, 500),
    $body-1: mat.define-typography-level(14px, 30px, 400),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $button: mat.define-typography-level(14px, 14px, 500) // Line-height must be unit-less fraction of the font-size.
    // $input:         mat-typography-level(inherit, 1.125, 400)
);


.feq-theme {
    @include mat.all-component-typographies($feq-typography);

    //p, div {
    //    font-family: Arial, "Helvetica Neue", sans-serif;
    //}
    //
    //.mat-form-field {
    //    //font-size: 12px;
    //}
    //.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    //    background-color: var(--primaryBackground);
    //}
    //
    //.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    //    border-color: var(--primaryBackground);
    //}
    //
    //.mat-form-field.mat-focused .mat-form-field-label {
    //    color: var(--primaryBackground);
    //}
    //
    //.mat-progress-bar-fill::after {
    //    background-color: var(--primaryBackground);
    //}
    //
    //.mat-form-field.mat-focused .mat-form-field-ripple {
    //    background-color: var(--primaryBackground);
    //}
    //
    //.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    //    color: var(--primaryBackground);
    //}
    //.mat-form-field.mat-focused.mat-primary .mat-select-arrow{
    //    color: var(--primaryBackground);
    //}
    //input[type="checkbox"] { __<<ngThemingMigrationEscapedComment0>>__
    //    filter:  hue-rotate( 249deg );
    //}
    //input { /* change "blue" browser chrome to yellow */
    //    caret-color: black !important;
    //}
    .mat-expansion-panel-spacing{
        margin:0 !important;

    }
    .expansion-panel-header > span:nth-of-type(2) {
        display: none;
    }
    .header-title-temp{
        margin-left: 10px;
    }


}

.mta-theme {
    @include mat.all-component-typographies($mta-typography);

    p, div {
        font-family: Roboto, Arial, "Helvetica Neue", sans-serif;
    }

}

.tkp-theme {
    @include mat.all-component-typographies($tkp-typography);

    .mat-button, .mat-raised-button, .mat-stroked-button, .mat-flat-button {
        border-radius: 0;
    }



    p, div {
        font-family: 'Open Sans', Arial, sans-serif;
    }
    .tw-btn-hover-transition:hover {
        color: white;
    }

    .tw-btn-hover-transition{
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    .tw-btn-hover-transition::after {
        content: "";
        background: #E6322D;
        position: absolute;
        z-index: -1;
        padding: 10px 20px;
        display: block;
        left: -20%;
        right: -20%;
        top: 0;
        bottom: 0;
        transform: skewX(-45deg) scale(0, 1);
    }

    .tw-btn-hover-transition:hover::after {
        transform: skewX(-45deg) scale(3, 1);
        transition: all 1s ease-out ;
    }
}

:root {
    --primaryColor: #FFF;
    --primaryBackground: #121944;
    --accentColor: rgba(0, 0, 0, .87);
    --accentBackground: #008000;
    --warnColor: #FFF;
    --warnBackground: #f44336;
    --appBackground: #FFF;
}



//.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
//    color: var(--primaryBackground) !important;
//}
//
//.mat-form-field.mat-focused .mat-form-field-ripple {
//    background-color: var(--primaryBackground);
//}
//.mat-progress-bar-fill::after {
//    background-color: var(--primaryBackground);
//}
//
.tw-primary {
    color: var(--primaryColor) !important;
    background-color: var(--primaryBackground) !important;
}

.tw-primary-color {
    color: var(--primaryBackground) !important;
}

.tw-accent {
    color: var(--accentColor) !important;
    background-color: var(--accentBackground) !important;
}

.tw-warn {
    color: var(--warnColor) !important;
    background-color: var(--warnBackground) !important;
}

.tw-app-background {
    background-color: var(--appBackground) !important;
}
.tw-snack-bar {
    background: var(--theme-primary-600) !important;
    margin: 14px !important;
}

.tw-snack-bar-red{
    background: red !important;
    margin: 14px !important;
}


.tw-sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: #fdfcff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;

}

.tw-sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 18px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.tw-sidenav a:hover {
    color: #f1f1f1;
}

.tw-sidenav .tw-closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}
.tw-overlay{
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.9);
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    opacity: 0;
    transition: opacity 1s;
}

@media screen and (max-height: 450px) {
    .tw-sidenav {padding-top: 15px;}
    .tw-sidenav a {font-size: 18px;}
}

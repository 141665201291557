.tw-flex {
    display: -webkit-flex; /* Safari */
    display: flex;
}

.tw-flex-wrap {
    display: -webkit-flex; /* Safari */
    -webkit-flex-wrap: wrap; /* Safari 6.1+ */
    flex-wrap: wrap;
}
.tw-flex-flow-row-wrap {
    flex-flow: row wrap;
}

.tw-row {
    flex-direction: row;
}
.tw-row-reverse {
    -webkit-flex-direction: row-reverse; /* Safari 6.1+ */
    flex-direction: row-reverse;
}

.tw-column {
    flex-direction: column;
}
.tw-input{
    padding: 2px;
    width: 100%;
}
.tw-padding-10{
    padding: 10px;
}
.tw-margin-5{
    margin: 5px;
}
.tw-margin-10{
    margin: 10px;
}
.tw-max-width-6{
    max-width: 600px;
}
.tw-center{
    max-width: 1000px;
    margin: auto;
    width: 100%;
}
.tw-center-600{
    margin: auto;
    width: 600px;
}

.tw-center-90p100 {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
}
.tw-margin-auto{
    margin: auto;
}
